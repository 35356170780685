$ribbon-margin: 10px;

.userindicator__dropdown {
    position: unset; // For mobile full width menu

    @media screen and (min-width: $breakpoint) {
        position: relative;
    }

    .btn {
        padding: 0.5em 1em;
    }

    i {
        margin-left: 0.5 * $padding;
        display: inline-block;
        vertical-align: middle;
    }
}

.userindicator__btn-img {
    margin-right: 0.5em;
    display: inline-block;
    vertical-align: middle;
    width: 3em;
    height: 3em;
    border-radius: 100%;
    border: 2px solid $body-bg;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.userindicator__btn-text {
    display: inline-block;
    vertical-align: middle;
    min-width: 120px;
    max-width: 180px;
}

.userindicator__hello {
    font-weight: normal;
}

.userindicator__dropdown-menu {
    position: absolute;
    top: $ribbon-margin;
    min-width: 100%;

    margin: 0;
    background-color: $brand-secondary;
    color: contrast-color($body-bg);
    border: 1px solid $body-bg;

    a,
    button {
        border-bottom: 1px solid $body-bg;
        font-weight: bold;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }

    &.show {
        top: -$ribbon-margin !important; // overwriting the general dropdown overwrite
        left: 0;
        right: 0;
    }

    @media screen and (min-width: $breakpoint) {
        &.show {
            top: calc(100% - 10px) !important; // overwriting the general dropdown overwrite
            left: auto;
        }
    }
}
