// Overwrites of the default styling

.video-wall {
    font-family: $font-family-sans-serif;
    min-width: 100% !important;
    border: none;
    min-height: 25rem;
    background-color: $gray;
    color: contrast-color($gray);

    @media (min-width: $breakpoint-md) {
        padding: 3 * $spacer;

        div {
            width: calc(100% - 6rem);
            top: 5 * $spacer;
        }

        .video-link {
            bottom: 8 * $spacer;
        }

        button {
            bottom: 3 * $spacer;
        }
    }
}

.video-wall strong,
.video-wall div {
    text-align: left;
}

.video-wall button {
    background-color: $brand-info;
    border-radius: 0;
    background-size: 2 * $spacer;
    height: 3 * $spacer;
}

// Needs updating once we can add a class name to the container div
.ck_embed_iframe__container {
    overflow: hidden;
    padding-top: 56.25%;  // 16:9 aspect ratio
    position: relative;

    .ck_embed_iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
