.navi {
    margin-top: 0.5 * $r-spacer;
    text-decoration: none;
    margin-right: $r-spacer;
}

.navi__list {
    list-style: none;
    padding: 0;
}

.navi__item {
    display: block;

    @media screen and (min-width: $breakpoint) {
        display: inline-block;
    }
}
