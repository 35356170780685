.breadcrumbs {
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;
    }

    a {
        color: inherit;
        text-transform: uppercase;
        text-decoration: none;
        font-size: $font-size-sm;
        font-weight: bold;
        margin-right: 1em;
    }
}
