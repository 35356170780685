$checkbox-size: 20px;

.pollquestionlist-container,
.poll__preliminary-results {
    padding-top: $spacer;
    border-bottom: 1px solid $gray-lightest;
}

.poll {
    padding: 1.5 * $spacer 0;
    border-top: 1px solid $gray-lightest;

    h2 {
        margin-top: 0;
        font-size: $font-size-lg;
        font-weight: 600;
        font-family: $font-family-base;
    }
}

.poll__rows {
    display: flex;
    flex-direction: column;
}

.poll-row {
    display: flex;
    position: relative;
    z-index: 1;

    margin-bottom: 0.5 * $spacer;

    @include clearfix;

    &--answered {
        left: 2 * $spacer;
        max-width: 90%;

        @media (min-width: $breakpoint) {
            max-width: 96%;
        }
    }

    .radio__text:after {
        color: $text-color-inverted;
        font-size: 0.9rem;
        line-height: 1.4;
    }

    .form-control {
        margin-left: $checkbox-size + 7px !important;
        margin-top: 0.5 * $spacer;
        width: auto;
    }
}

// User answer view
.poll-row.radio {
    flex-direction: column; // other answer text
}

.poll-row__number,
.poll-row__label {
    display: inline-block;
    margin: 0;
    margin-right: 0.2em;
}

.poll-row__radio {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.poll-row__number {
    padding-left: 0.5em;
    min-width: 5em;
    font-weight: bold;
}

.poll-row__bar {
    background-color: $brand-primary-tint;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.3em;
    z-index: -1;
    width: 0;
    min-width: 0.4em;

    transition: width 0.3s;

    @media print {
        border: 1px solid $border-color;
    }
}

.poll__highlight {
    background-color: $brand-tertiary-tint;
}

.poll__btn--wrapper {
    display: flex;
    flex-direction: row-reverse;
}

.poll__btn--link {
    @extend .btn--link;

    margin-right: $spacer;
}

.poll__btn--light {
    @extend .btn--light;
}

.poll__btn--dark {
    @extend .btn--default;
}

.poll__help-text {
    color: $text-color-gray;
    margin-bottom: 0.5 * $spacer;
}

.poll__char-counter {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end; // needed for open answer
    color: $text-color-gray;
    font-size: $font-size-sm;
}

.poll-row__container {
    position: relative;
}

.poll-row__chosen {
    position: absolute;
    top: 0.25 * $spacer;
    color: $brand-tertiary;
}

// User answer view: Open and Other answers
.poll-slider__item {
    padding-top: $spacer;
}

.poll-slider__answer {
    padding-bottom: 1.5 * $spacer;
    overflow-wrap: break-word;

    i {
        color: $brand-tertiary;
        margin-left: -1.25 * $spacer;
    }
}

.poll-slider__count {
    font-size: $font-size-sm;
    color: $text-color-gray;
    position: absolute;
    bottom: 0;
}

.spinner-border {
    margin-right: 3 * $spacer;
}

.poll-slider {
    position: relative;

    background-color: $brand-tertiary-tint;
    margin-bottom: $spacer;
    border-radius: 0.3 * $spacer;

    // slick overwrites - nested for specificity
    .slick-prev {
        left: revert !important;
        right: 4 * $spacer !important;
    }

    .slick-next {
        right: 1.5 * $spacer !important;
    }

    .slick-prev,
    .slick-next {
        @extend .btn--none;
        background-color: $brand-tertiary;

        position: absolute;
        top: revert;
        bottom: 0;
        text-align: center;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        z-index: 1; // when tile links overlap

        &:hover {
            background-color: $brand-tertiary;
        }

        &:before {
            color: $brand-tertiary-tint;
            opacity: 100%;
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            font-size: $font-size-base;
            line-height: 1.4rem;
        }
    }

    .slick-prev:before {
        content: "\f060"; // arrow-left
    }

    .slick-next:before {
        content: "\f061"; // arrow-right
    }

    .slick-disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }

    // Ensure centermode works
    .slick-slide {
        height: auto;
        position: relative;
        padding: 0 $spacer 0 (2 * $spacer);

        @media (min-width: $breakpoint) {
            padding: 0 3 * $spacer;
        }
    }

    .slick-track {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
    }
}
