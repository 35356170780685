$creator-count-circle-size: 2.5rem;
$creator-count-circle-offset: 1rem;

.creator-count__circles {
    display: inline-block;
    vertical-align: middle;
}

.creator-count__circle {
    display: inline-block;
    vertical-align: middle;
    width: $creator-count-circle-size;
    height: $creator-count-circle-size;
    border-radius: 50%;

    background-position: center; // for image
    background-size: cover;
}

.creator-count__circle--2 {
    margin-left: -$creator-count-circle-offset;
}

.creator-count__circle--3 {
    margin-left: -$creator-count-circle-offset;
}

.creator-count__circle--count {
    margin-left: -$creator-count-circle-offset;

    background: $brand-info;
    color: $body-bg;
    text-align: center;
    line-height: $creator-count-circle-size;
}

.creator-count__comment-count {
    display: inline-block;
    vertical-align: bottom;
}
