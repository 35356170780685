// See https://csswizardry.com/2013/04/shame-css/ about this file

// CKEditor applies `box-sizing: content-box` everywhere. The outer element has
// a 1px border, so if set to `width: 100%` it will take up an additional 2px.
.cke_chrome {
    box-sizing: border-box !important;
}

// CKEditor uses background images for its toolbar
.cke_button_icon {
    // stylelint-disable-next-line scale-unlimited/declaration-strict-value
    -webkit-print-color-adjust: exact;
}

// CKEditor remove url box from image upload dialog box
.cke_dialog_image_url {
    .cke_dialog_ui_hbox_first {
        display: none;
    }
}

// safari doesn't allow styling of these otherwise
// see https://css-tricks.com/webkit-html5-search-inputs/
// stylelint-disable property-no-vendor-prefix
input[type="search"] {
    -webkit-appearance: none;
}
// stylelint-enable

// used by adhocracy4
.submit-button {
    @extend .btn;
    @extend .btn--default;
}

.cancel-button {
    @extend .btn;
    @extend .btn--light;
}

// used by adhocracy4 for the follow button, long-term we'd like
// our own classes.
.btngroup .btn {
    @extend .btn--light;
    @extend .btn--small;
}

// maps usually have a height of 300px in their element style, defined in adhocracy4.
// However, 300px is not enough for the list view so we add a min-height here instead.
.map-list [data-map] {
    min-height: 500px;
}

// stops padding being added by modal when opened
body:not(.modal) {
    padding-right: 0 !important;
}

.modal.fade.show,
.modal.fade {
    padding-right: 0 !important;
}
