.infographic {
    font-family: $font-family-base;
}

.infographic__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $breakpoint-xs) {
        flex-direction: row;
        align-items: flex-start;
    }

    @media screen and (min-width: $breakpoint) {
        align-items: center;
    }
}

.infographic__icon-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: $breakpoint-xs) {
        flex-direction: column;
        min-width: 25%;
    }

    @media screen and (min-width: $breakpoint) {
        flex-direction: row;
        min-width: 0;
    }
}

.infographic__icon {
    padding-top: 0.5 * $spacer;
    padding-bottom: 0.5 * $spacer;
    width: 6rem;
    flex-shrink: 0;

    img {
        width: 70%;
    }

    @media screen and (min-width: $breakpoint-xs) {
        width: 4rem;
        flex-shrink: 1;
    }

    @media screen and (min-width: $breakpoint) {
        width: 8rem;
    }
}

.infographic__text {
    @media screen and (min-width: $breakpoint-xs) {
        text-align: center;
    }

    @media screen and (min-width: $breakpoint) {
        text-align: left;
    }
}

.infographic__arrow {
    display: none;

    img {
        width: 100%;
    }

    @media screen and (min-width: $breakpoint-xs) {
        padding-top: 2 * $spacer;
        display: inline;
        width: 7rem;
    }

    @media screen and (min-width: $breakpoint) {
        padding-right: 3 * $spacer;
        padding-left: 3 * $spacer;
        padding-top: 0;
        width: 13rem;
    }
}

.infographic__link {
    @media screen and (min-width: $breakpoint) {
        padding-bottom: 7 * $spacer;
    }
}

.infographic__info-footer {
    @extend .fixed-bottom;

    width: 100%;
    height: 150px;
    background-color: $brand-secondary;

    .infographic__link {
        @media screen and (min-width: $breakpoint) {
            padding-bottom: 0;
        }
    }
}

.infographic__info-screen {
    @extend .mt-5;
    width: 100%;
}
