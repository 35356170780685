table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 0.8em 0;
    border-bottom: 1px solid $border-color;
}

thead th {
    text-align: left;
    padding-bottom: 0.8em;
}

.td--action {
    text-align: right;

    i {
        font-size: $font-size-lg;
    }
}
