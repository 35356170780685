.account__org-image {
    height: 2 * $spacer;
    max-width: 2 * $spacer;
    margin-right: 0.5 * $spacer;
    flex: 0 0 (2 * $spacer);
}

.account__org-image--bg {
    background-color: $brand-primary;
}

.account__org-name {
    margin-top: 0.5 * $spacer;
    font-size: $font-size-base;
    font-weight: normal;
}

@media screen and (min-width: $breakpoint) {
    .account__org-info .form-check {
        margin-left: 2.5 * $spacer;
    }
}
