/*
 * CKEditor 5 (v40.2.0) content styles.
 * Generated on Wed, 13 Dec 2023 08:57:10 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
    --ck-color-image-caption-background: hsl(0deg, 0%, 97%);
    --ck-color-image-caption-text: hsl(0deg, 0%, 20%);
    --ck-color-mention-background: hsla(341deg, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341deg, 100%, 30%);
    --ck-color-selector-caption-background: hsl(0deg, 0%, 97%);
    --ck-color-selector-caption-text: hsl(0deg, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201deg, 97%, 72%);
    --ck-highlight-marker-green: hsl(120deg, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345deg, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60deg, 97%, 73%);
    --ck-highlight-pen-green: hsl(112deg, 100%, 27%);
    --ck-highlight-pen-red: hsl(0deg, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
    height: auto;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content img.image_resized {
    height: auto;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}

/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
    display: flex;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol {
    list-style-type: decimal;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol {
    list-style-type: lower-latin;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol {
    list-style-type: lower-roman;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol {
    list-style-type: upper-latin;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol {
    list-style-type: upper-roman;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul {
    list-style-type: disc;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul {
    list-style-type: circle;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul {
    list-style-type: square;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul {
    list-style-type: square;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
    margin-top: 0;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}

.ck-content .text-tiny {
    font-size: 0.7em;
}

.ck-content .text-small {
    font-size: 0.85em;
}

.ck-content .text-big {
    font-size: 1.4em;
}

.ck-content .text-huge {
    font-size: 1.8em;
}
