.marked {
    position: relative;
    padding-left: 0.1em;
    padding-right: 0.5em;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        top: 0.3em;
        left: 0;
        width: 100%;
        height: 90%;
        z-index: -1;

        // background-color: $bg-tertiary;
    }
}

.marked-box {
    background-color: $bg-light;
    line-height: 1.5;
    box-decoration-break: clone;
    display: inline;
    box-shadow: 0 0.4rem $bg-light, inset 0 1.3rem $body-bg;

    @media screen and (min-width: $breakpoint-lg) {
        box-shadow: 0 0.4rem $bg-light, inset 0 2.2rem $body-bg;
    }
}
