.project-tile-carousel {
    position: relative;
    margin: $spacer 0.75 * $spacer;

    // overwrites for tiles in carousel
    .tile {
        height: 450px;
        margin: 0;
        display: flex !important;
    }

    .tile__text {
        max-height: 6em;
        overflow: hidden;
    }

    .tile__image {
        min-height: 12rem;
    }

    // slick overwrites - nested for specificity
    .slick-list {
        margin: 0 -0.5 * $spacer;
    }

    .slick-slide {
        margin: 0 0.5 * $spacer;
    }

    .slick-track {
        padding-bottom: $padding;
        margin-left: 0;
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next {
        right: -20px;
    }

    .slick-prev,
    .slick-next {
        top: 40%;
        text-align: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid $border-color;
        background-color: $body-bg;
        z-index: 1; // for when tile links overlap - on mobile people swipe

        &:hover,
        :focus {
            background-color: $body-bg;
        }

        &:focus-visible {
            @include fake-focus;
        }

        &:before {
            color: $gray;
            opacity: 1;
            font-family: "Font Awesome 5 Free", sans-serif;
            font-weight: 900;
            font-size: $font-size-lg;
            line-height: 1.3rem;
        }
    }

    .slick-prev:before {
        content: "\f104"; // angle-left
    }

    .slick-next:before {
        content: "\f105"; // angle-right
    }

    .slick-arrow.slick-disabled {
        display: none !important;
    }
}

@media screen and (min-width: $breakpoint-md) {
    .project-tile-carousel {
        margin: $spacer 0;
    }

    .project-tile-carousel__wrapper {
        margin-top: 4 * $r-spacer;
        margin-bottom: 3 * $r-spacer;
    }
}
