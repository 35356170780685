.language-choice-container {
    display: flex;
    flex-direction: row;
}

.language-choice-list label {
    display: block;
}

.language-choice-container > .checkbox-list {
    padding-left: 0;
    margin-left: 0;
    list-style: none;

    &.nav {
        flex-wrap: nowrap;
    }

    input {
        display: none;
    }

    li {
        display: inline-block;
    }

    a {
        color: $black;
        margin-right: 2px;
    }

    .active button {
        background-color: $bg-tertiary;
    }
}

.language-choice-list .dropdown {
    float: left;
}

.language-choice {
    display: none;

    &.active {
        background-color: $bg-tertiary;
    }
}

:checked + .language-choice {
    display: block;
}

.language-choice-panel {
    display: none;

    &.active {
        display: block;
    }
}
