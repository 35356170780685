// stylelint-disable selector-max-specificity

// to style select elements for all browsers
@import "~select2/src/scss/core";

.select2-container--default {
    .select2-selection--single {
        height: 40px;
        padding-top: 4px;
        border: 2px solid $input-border-color;
        border-radius: 0;
    }

    .select2-results__option[aria-selected="true"] {
        background-color: $body-bg;
        color: contrast-color($body-bg);
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $bg-tertiary;
        color: contrast-color($print-black);
    }

    .select2-selection--single .select2-selection__arrow {
        height: 40px;
        width: 40px;

        b {
            border-width: 7px 7px 0;
        }
    }

    .select2-selection--single .select2-selection__rendered {
        padding-right: 2 * $spacer;
    }

    &.select2-container--open {
        .select2-selection--single > .select2-selection__arrow > b {
            border-width: 0 7px 7px;
        }
    }

    &.select2-container--disabled {
        .select2-selection--multiple .select2-selection__choice {
            opacity: 50%;
        }
    }
}

.select2-dropdown {
    border-radius: 0;
}
