.a4-textarea-with-counter {
    padding: $spacer 0;
    margin-bottom: $spacer / 2;

    &__textarea {
        resize: vertical;
        width: 100%!important;
        overflow-y: auto;
        line-height: 1.5;
        max-height: 500px;
    }
}
