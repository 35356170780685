// mimic native browser focus
// see https://ghinda.net/article/mimic-native-focus-css/
@mixin fake-focus {
    // stylelint-disable scale-unlimited/declaration-strict-value
    outline: 2px solid Highlight;
    outline: 5px auto -webkit-focus-ring-color;
    // stylelint-enable
}

// mimic native browser focus with box-shadow, which respects border-radius
@mixin fake-focus-shadow {
    // stylelint-disable scale-unlimited/declaration-strict-value
    box-shadow: 0 0 1px 3px Highlight;
    // stylelint-enable
}

@mixin secondary-text {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

// stylelint-disable
@function contrast-color($color) {
    @if  lightness($color) > 50 {
        @return #000;
    }

    @else {
        @return #FFF;
    }
}
// stylelint-enable
