.hero-unit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 -1 * $spacer 3.5 * $spacer -1 * $spacer;

    position: relative;

    min-height: $hero-height-mobile;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    color: $text-color-inverted;

    @media screen and (min-width: $breakpoint) {
        min-height: $hero-height;
        margin: 0 0 3.5 * $spacer 0;
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;

        position: absolute;
        bottom: 0;
    }
}
