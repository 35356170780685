@font-face {
    font-family: "SourceSerifPro";
    src: url("../fonts/SourceSerifPro-Regular.otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "SourceSerifPro";
    src: url("../fonts/SourceSerifPro-It.otf");
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "SourceSansPro";
    src: url("../fonts/SourceSansPro-Regular.otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "SourceSansPro";
    src: url("../fonts/SourceSansPro-It.otf");
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "SourceSansPro";
    src: url("../fonts/SourceSansPro-Semibold.otf");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "SourceSansPro";
    src: url("../fonts/SourceSansPro-Bold.otf");
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}
