@mixin multi-line-icon($lines, $line-height: 1.4) {
    $font-size: 0.7 * $lines;
    $size: $lines * $line-height / $font-size;

    line-height: $size;
    min-width: 1.5em;
    font-size: $font-size * 100%;
}

@mixin multi-line-main($lines, $line-height: 1.4) {
    $font-size: 0.7 * $lines;
    $size: $lines * $line-height / $font-size;

    margin-left: $size * 1em;
}

.action {
    @include clearfix;
    padding: 0.6em 0;
}

.action__icon,
.action__icon--sm {
    background-color: $bg-tertiary;
    color: $brand-primary;
    text-align: center;
}

.action__icon {
    @include multi-line-icon(2);
    padding: 0 0.5 * $padding;
    float: left;
    min-width: 40px;
}

.action__icon--sm {
    @include multi-line-icon(1.5);
    padding: 0 0.35 * $padding;
    width: 32px;
}

.action__main {
    @include multi-line-main(2);
    margin-left: 3 * $padding;
}

.action--withdate {
    position: relative;
}

.action__date {
    color: $gray;
    font-size: $font-size-sm;
}

.action__icon--stacked {
    color: $brand-primary;
    position: absolute;
    font-size: 0.7rem;
    top: 1.6rem;
    left: 0.95rem;
}
