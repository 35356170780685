.captcheck_box {
    font-family: $font-family-base;
    color: $text-color;
    border: 1px solid $border-color;
    display: inline-block;
    padding: 3px;
    margin: 5px 2px 5px 1px;
    text-decoration: none;
}

.captcheck_label_message,
.captcheck_label_message b {
    color: $text-color;
    font-family: $font-family-base;
}

.captcheck_answer_label {
    border: 0;
}

.captcheck_answer_label > input {
    visibility: hidden;
    position: absolute;
}

.captcheck_answer_label > input + img {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 3px;
    min-width: 32px;
    width: 18%;
    max-width: 64px;
}

.captcheck_answer_label > input:checked + img {
    cursor: pointer;
    border: 2px solid $text-color-gray;
    border-radius: 3px;
}

.captcheck_error_message {
    color: $danger;
}

.captcheck_question_image {
    display: initial;
}

:not(.react_captcha).captcheck_question_access {
    display: none;
}

.captcheck_alt_question_button {
    display: block;
    font-size: 80%;
    cursor: pointer;
    color: inherit;
    text-decoration: inherit;
    border: 0;
}

.captcheck_alt_question_button:focus {
    outline: 0;
}

.captcheck_alt_question_button:focus-visible {
    outline: auto;
}

.captcheck_answer_images > a:focus {
    outline: 0;
}

.captcheck_answer_images > a:focus-visible {
    outline: auto;
}

.captcheck_answer_images {
    display: initial;
}

:not(.react_captcha).captcheck_answer_access {
    display: none;
}
