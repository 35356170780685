// used for text commenting
.editpoll__question-container,
.editpoll__actions-container,
.commenting {
    margin-bottom: $spacer;
    margin-top: $spacer;

    @extend .row;
}

.editpoll__question,
.commenting__content {
    margin-bottom: $spacer;

    @extend .col-lg-9;
}

.editpoll__question--border,
.commenting__content--border {
    margin-bottom: calc($spacer / 2);
    padding: $padding;
    border: 1px solid $border-color;
}

.commenting--toc__button {
    display: block;
    text-align: left;
    width: 100%;

    &.active {
        color: $brand-primary;
    }
}

.editpoll__question-actions,
.editpoll__menu-container,
.commenting__actions {
    margin-bottom: $spacer;
    text-align: right;
    display: inline;

    @extend .col-lg-3;
}

@media (min-width: $breakpoint) {
    .commenting__actions {
        text-align: left;
    }
}
