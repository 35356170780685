.pagination {
    margin-top: 1rem;
}

.pagination-item {
    min-width: 40px;

    @media screen and (min-width: $breakpoint-xs) {
        min-width: 150px;
    }

    a {
        background-color: $body-bg;
        padding: 0.2em;
        border: 2px solid $border-color;
        text-align: center;
        text-decoration: none;
        font-size: $font-size-md;
        font-family: $font-family-base;
    }

    a:hover,
    a:focus {
        background-color: $bg-tertiary;
    }
}

.pagination-text {
    font-size: $font-size-md;
    font-family: $font-family-base;
    padding: 0.5em;
}
