.list-item {
    padding: 1.5 * $padding;
    display: block;
    position: relative;
    border: 1px solid $gray-lightest;
    background-color: $body-bg;
    margin-bottom: $spacer;
    page-break-inside: avoid;
    overflow-wrap: break-word;

    &.selected {
        background-color: $bg-tertiary;
    }

    &.selected:focus {
        outline: none;
    }

    &:hover .list-item__hover {
        text-decoration: underline;
    }
}

.list-item--squashed {
    border: 0;
    margin-bottom: 2px;

    &:hover {
        box-shadow: none;
    }

    @media print {
        border: 1px solid $border-color;
    }
}

.list-item--spaced {
    padding: 1.5 * $padding;
    border: 0;
    margin-bottom: 2 * $spacer;
    min-height: 10em;

    @media print {
        border: 1px solid $border-color;
    }
}

.list-item__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.list-item--has-image {
    padding: 0;
}

.list-item--has-image__wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-xs) {
        flex-direction: row;
    }
}

.list-item--has-image__image {
    position: relative;
    min-height: 7.5em;
    flex: 0 0 7.5em;

    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: $brand-primary;
}

.list-item--has-image__copyright {
    position: absolute;
    bottom: 0;
}

.list-item--has-image__body {
    padding: 0.7em $padding;
    flex: 1 1 auto;
}

.list-item__labels {
    @include clearfix;
    margin: 0 0 0.4rem;
}

.list-item__badge--moderator-feedback {
    float: right;
    margin-left: 0.5em;
}

.list-item__author {
    margin-right: 0.3em;
}

.list-item__stats {
    float: right;
    margin-left: 0.5em;
}

.list-item__action {
    display: inline;
}

.list-item .is-read-only {
    padding: 0;
}

.list-item__comments {
    margin-left: 0.7em;
}

.list-item-empty {
    padding: 0.7em $padding;
    text-align: center;
}

.list-item__status--active {
    color: $brand-primary;
}

.list-item__cta {
    text-transform: uppercase;
    color: $brand-info;
    box-shadow: none;
}
